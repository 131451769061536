import React, { useCallback, useEffect, useState } from 'react';
import PropTypes from 'prop-types';
import { Anchorme } from 'react-anchorme';
import { Link } from 'gatsby';
import Tooltip from 'react-bootstrap/Tooltip';
import Button from 'react-bootstrap/Button';
import OverlayTrigger from 'react-bootstrap/OverlayTrigger';
import Modal from 'react-bootstrap/Modal';
import Dropdown from 'react-bootstrap/Dropdown';
import Container from 'react-bootstrap/Container';
import Row from 'react-bootstrap/Row';
import Col from 'react-bootstrap/Col';
import { FontAwesomeIcon } from '@fortawesome/react-fontawesome';
import { faFileDownload, faQuestionCircle, faListUl } from '@fortawesome/free-solid-svg-icons';

import ROUTES from "utils/routes";
import { withApolloClient } from "utils/graphqlSetup"
import './Datapage.scss';

import { useAuthentication } from 'components/firebase/AuthUserContext';
import getNumberOfDownloads from 'utils/trackFileDownload/getNumberOfDownloads';
import recordFileDownload from 'utils/trackFileDownload/recordFileDownload';
import report_level_data from './codesets/report_level_data.pdf';
import client_level_data from './codesets/client_level_data.pdf';
import bill_level_data from './codesets/bill_level_data.pdf';
import issue_level_data from './codesets/issue_level_data.pdf';
import network_data from './codesets/network_data.pdf';
import text_data from './codesets/text_data.pdf';
import { StaticImage } from 'gatsby-plugin-image';

const datasets = {
  report_level: {
    title: "Report Level",
    citation: "Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.” Working paper available from https://web.mit.edu/insong/www/pdf/lobbyview.pdf",
    description: "This is the main LobbyView dataset that includes all quarterly report fillings between 1999 and 2020. Researchers can merge this dataset, using the universally unique identifiers that we developed, with other LobbyView datasets to investigate more detailed lobbying activities. This dataset also includes unique firm-level identifiers from Compustat and Bureau van Dijk so that researchers can examine firm-level political behavior along with their financial activities. Note that lobbying reports were filed biannually until 2007 when the Lobbying  Disclosure Act of 1995 was amended by the Honest Leadership and Open Government Act of 2007.",
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis",
    related_publication: "Political Cleavages within Industry: Firm-level Lobbying for Trade Liberalization, American Political Science Review (2017), Vol. 111, No. 1, pp. 1-20.",
    notes: "This dataset allows researchers to investigate firm-level lobbying activities using the unique identifiers: gvkey and bvdid. For further details about the filings, please see https://lobbyingdisclosure.house.gov/",
    csv_download: '/csv/dataset___report_level.zip',
    pdf: report_level_data
  },
  client_level:{
    title: "Client Level",
    citation: "Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.” Working paper available from https://web.mit.edu/insong/www/pdf/lobbyview.pdf",
    description: "This data set provides client-level information such as client’s gvkey and bvdid whenever it is available. By merging this data with the Report Level Data, researchers can study firm-level political behavior along with their financial activities. We also provide the North American Industry Classification System (NAICS) code (when available) so that one can conduct an industry-level analysis.",
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis",
    related_publication: "",
    notes: <div>This dataset allows researchers to investigate firm- and industry-level lobbying activities using the unique identifiers: <code>gvkey</code>, <code>bvdid</code>, and <code>NAICS</code>. One of the primary difficulties to create a client-level data set is to improve named entity-disambiguation ensuring that the same clients with slightly different names across lobbying reports appear under the same unique <code>client_uuid</code> (e.g., "Apple" vs. "Apple Inc." vs. "APPLE COMPUTER"). While LobbyView provides links to common firm- and industry-level identifiers, our automated pipeline sometimes results in incorrect matchings. We try to improve the quality of our data set based on a crowdsourcing model. If you see any potential errors in the client-level data, please help us identify and fix them using <a href="https://mit.co1.qualtrics.com/jfe/form/SV_3WurpOnakPraKGh" target="_blank" rel="noopener noreferrer">this link</a>. We will keep updating our data set based on the community efforts. Thank you very much!</div>,
    csv_download: '/csv/dataset___client_level.zip',
    pdf: client_level_data
  },
  bill_level: {
    title: "Bill Level",
    citation: "Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.” Working paper available from https://web.mit.edu/insong/www/pdf/lobbyview.pdf",
    description: "This data set provides detailed information about congressional bills such as bill summary and bill sponsor. Researchers can study the details of each lobbied bill they found from this webpage using this data. We suggest that researchers use Text Data to search either bill number or bill title to merge lobbying data with this data set (We plan to provide a direct link to other LobbyView datasets in our future updates).",
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, bill lobbying, congress",
    related_publication: "Kim, In Song. Political Cleavages within Industry: Firm-level Lobbying for Trade Liberalization, American Political Science Review (2017), Vol. 111, No. 1, pp. 1-20.\n\nKim, In Song and Dmitriy Kunisky. Mapping Political Communities: A Statistical Analysis of Lobbying Networks in Legislative Politics. Forthcoming in Political Analysis.",
    notes: "This dataset allows researchers to investigate firm-level lobbying activities on congressional bills.",
    csv_download: '/csv/dataset___bills.zip',
    pdf: bill_level_data
  },
  issue_level: {
    title: "Issue Level",
    citation: "Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.” Working paper available from https://web.mit.edu/insong/www/pdf/lobbyview.pdf",
    description: "This data set allows researchers to study the variation is lobbied issues. Users can merge this data with the Report Level Data using the universally unique identifiers (uuid) of lobbying report to understand which issue the client lobbied. Furthermore, we also provide the list of government entities (House(s) of Congress and Federal agencies) that were contacted for each lobbied issue.",
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, lobbied issues",
    related_publication: "Kim, In Song. Political Cleavages within Industry: Firm-level Lobbying for Trade Liberalization, American Political Science Review (2017), Vol. 111, No. 1, pp. 1-20.",
    notes: "This dataset allows researchers to investigate issue-level lobbying activities and contacted government entities.",
    csv_download: '/csv/dataset___issue_level.zip',
    pdf: issue_level_data
  },
  network_data:{
    title: "Network Data",
    citation: "Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.” Working paper available from https://web.mit.edu/insong/www/pdf/lobbyview.pdf",
    description: "This dyadic data set (client and politician level) allows researchers to conduct network analysis. We provide the number of times the given interest group lobbied on the congressional bills sponsored by the politician in each congressional session. Using this data set, users can construct an adjacency matrix to study legislative politics.",
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, network analysis, lobbying networks",
    related_publication: "Kim, In Song and Dmitriy Kunisky. Mapping Political Communities: A Statistical Analysis of Lobbying Networks in Legislative Politics. Forthcoming in Political Analysis.",
    notes: "",
    csv_download: '/csv/dataset___network_client_legislator.zip',
    pdf: network_data
  },
  text_data: {
    title: "Text Data",
    citation: "Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.” Working paper available from https://web.mit.edu/insong/www/pdf/lobbyview.pdf",
    description: "This data set allows researchers to study the textual description of lobbying activities for each lobbied issue. Users can merge this data with the Report Level Data and Issue Level Data using the universally unique identifiers (uuid) of lobbying report and issue code. ",
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, firm-level analysis, text data, text analysis of lobbying",
    related_publication: "Kim, In Song. Political Cleavages within Industry: Firm-level Lobbying for Trade Liberalization, American Political Science Review (2017), Vol. 111, No. 1, pp. 1-20.\n\nKim, In Song and Dmitriy Kunisky. Mapping Political Communities: A Statistical Analysis of Lobbying Networks in Legislative Politics. Forthcoming in Political Analysis.",
    notes: "This dataset allows researchers to conduct text analysis of lobbying activities.",
    csv_download: '/csv/dataset___issue_text.zip',
    pdf: text_data
  },
  lobbyist_level: {
    title: "Lobbyist Level Data",
    citation: `Rodrigo Garza, Nicholas Liu, Cole Ruehle (2023). “Who Are America’s Lobbyists?: A Comprehensive Dataset of Histories, Donations, and Influence”

    Kim, In Song (2018). “LobbyView: Firm-level Lobbying & Congressional Bills Database.” Working paper available from https://web.mit.edu/insong/www/pdf/lobbyview.pdf`,
    description: "To fill the gap in detailed and accurate data on political lobbying, we built a highly granular lobbyist level dataset by extracting valuable information including a lobbyist’s past government agencies, past congressional committee positions, and past legislators that they worked for (from original lobbyist filings) using our political science oriented natural language processing system. Additionally, we used novel approaches such as  detecting passthrough donations, tracking employers, and analyzing neglected text fields for donation insights to disambiguate the source of campaign contributions from lobbyists to Federal Politicians.",
    keyword: "Lobbying Disclosure Act of 1995, lobbying reports, contribution reports, firm-level analysis, network analysis, lobbying networks",
    related_publication: "Kim, In Song. Political Cleavages within Industry: Firm-level Lobbying for Trade Liberalization, American Political Science Review (2017), Vol. 111, No. 1, pp. 1-20.\n\nKim, In Song and Dmitriy Kunisky. Mapping Political Communities: A Statistical Analysis of Lobbying Networks in Legislative Politics. Forthcoming in Political Analysis.",
    // notes: "This dataset allows researchers to conduct text analysis of lobbying activities.",
    // csv_download: '/csv/dataset___issue_text.zip',
    // pdf: text_data,
    show_download_count: false,
    codebook_text: "Codebook is available in the data download link", //this is hacky
    data_download_link: "https://www.dropbox.com/sh/9mfasvjl45h3im9/AABDZutJ1Ri-GPHwk5t-SHTQa?dl=0", //this is hacky
    custom_images: [
      <StaticImage src="../dataset/images/LobbyistsToConnectedCongressionalCommittees.png" alt="Lobbyists and Connected Congressional Committees"/>,
      <StaticImage src="../dataset/images/LobbyistsToConnectedGovernmentAgencies.png" alt="Lobbyists and Connected Government Agencies"/>,
      <StaticImage src="../dataset/images/LobbyistMap.png" alt="Lobbyist Map"/>,
    ], //this is hacky
  }
}

const Datapage = (props) => {
  const [numberFileDownloads, setNumberFileDownloads] = useState(0)
  const [showWarning, setShowWarning] = useState(true)

  const authUser = useAuthentication()

  //updates the number of file downloads being show in real time
  const updateFileDownloadCountState = useCallback((fileName) => {
    getNumberOfDownloads(props.client, fileName).then((number) => {
      setNumberFileDownloads(number)
    }).catch((error) => {
      console.log(error)
    })
  }, [props.client])

  const dataset = datasets[props.id]
  useEffect(() => {
    if(dataset) {
      updateFileDownloadCountState(dataset.csv_download)
    }
  }, [dataset, updateFileDownloadCountState])


  //when a user clicks the file for download, the file link is hidden and calss for the update file function
  const clickDownloadFile = (fileName) => {
    const a = document.createElement('a'); //create dummy anchor element
    a.setAttribute("href", fileName); //set the data to download
    a.click(); //click to download file
    a.remove(); //remove anchor element

    recordFileDownload(props.client, fileName).then(
      () => updateFileDownloadCountState(fileName)
    ).catch(console.error)
  }


  const showDownloadContent = () => {
    if(authUser) {
      const csv_download = dataset.csv_download

      return(
        <Col sm={12} md={12} lg={9}>
          <ul className="list-group">
            <b className="metric"> &nbsp;&nbsp; Download Files</b>
            <li className="list-group-item" onClick={() => clickDownloadFile(csv_download)}>
              CSV <FontAwesomeIcon className="download" icon={faFileDownload}/>
            </li>
          </ul>
        </Col>
      )
    } else {
      //when an unauthenticated user visits the page, a modal popup warning is show to the user
      //passing the location object as state so we will be redirected to this page on sign in

      const message = <span>You must be <b><Link to={ROUTES.SIGN_IN} state={{from: props.location}}>logged in</Link></b> to access dataset file downloads</span>

      return(
        <Col>
          <Modal show={showWarning} onHide={() => setShowWarning(false)}>
            <Modal.Header closeButton>Warning</Modal.Header>
            <Modal.Body>
              <p>{message}</p>
            </Modal.Body>
          </Modal>
          {message}
        </Col>
      )
    }
  }

  const openPDF = (pdf) => window.open(pdf)

  if (dataset) {
    return (
      <Container className="datapage">
        <h1>{dataset.title}</h1>
          <Row className="citation">
            <Col className="header" sm={12} md={12} lg={12}>
              <Dropdown>
                <Dropdown.Toggle variant="secondary">
                  <FontAwesomeIcon icon={faListUl}/> Cite Dataset
                </Dropdown.Toggle>

                <Dropdown.Menu>
                  <Dropdown.Item href="http://web.mit.edu/insong/www/research/lobbyview.bib" target="_blank" rel="noreferrer noopener">BibTex</Dropdown.Item>
                </Dropdown.Menu>
              </Dropdown>
            </Col>
            <Col className="background" sm={12} md={12} lg={12}>
              <Anchorme target="_blank" rel="noreferrer noopener">
                {dataset.citation}
              </Anchorme>
            </Col>
          </Row>

        {dataset.data_download_link && (
          <Row className="title">
            <Col sm={12} md={4} lg={3}>
                <b>Data Download Link</b>
            </Col>
            <Col sm={12} md={8} lg={9}>
              <a href={dataset.data_download_link} target="_blank">{dataset.data_download_link}</a>
            </Col>
          </Row>
        )}

        <Row className="title">
          <Col sm={12} md={4} lg={3}>
              <b>Description</b>
          </Col>
          <Col sm={12} md={8} lg={9}>
            {dataset.description}
          </Col>
        </Row>

        {dataset.pdf && (
          <Row className="title">
            <Col sm={12} md={4} lg={3}>
                <b>Codebook &nbsp;
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        <div>Codebook includes detailed descriptions of the variables included in the dataset.</div>
                      </Tooltip>
                    }>
                    <FontAwesomeIcon className="question" icon={faQuestionCircle}/>
                  </OverlayTrigger>
                </b>
            </Col>
            <Col sm={12} md={8} lg={9}>
              Click <span onClick={() => openPDF(dataset.pdf)}>here</span> for the codebook pdf
            </Col>
          </Row>
        )}

        {dataset.codebook_text && (
          <Row className="title">
            <Col sm={12} md={4} lg={3}>
                <b>Codebook &nbsp;
                  <OverlayTrigger
                    placement="right"
                    overlay={
                      <Tooltip>
                        <div>Codebook includes detailed descriptions of the variables included in the dataset.</div>
                      </Tooltip>
                    }>
                    <FontAwesomeIcon className="question" icon={faQuestionCircle}/>
                  </OverlayTrigger>
                </b>
            </Col>
            <Col sm={12} md={8} lg={9}>
              {dataset.codebook_text}
            </Col>
          </Row>
        )}
        

        <Row className="title">
          <Col sm={12} md={4} lg={3}>
              <b>Keyword</b>
          </Col>
          <Col sm={12} md={8} lg={9}>
            {dataset.keyword}
          </Col>
        </Row>

        <Row className="title">
          <Col sm={12} md={4} lg={3}>
              <b>Related Publication &nbsp;
                <OverlayTrigger
                  placement="right"
                  overlay={
                    <Tooltip>
                      <div>Publications that use the data from this Dataset.</div>
                    </Tooltip>
                  }>
                  <FontAwesomeIcon className="question" icon={faQuestionCircle}/>
                </OverlayTrigger>
              </b>
          </Col>
          <Col className="related_publication" sm={12} md={8} lg={9}>
            {dataset.related_publication}
          </Col>
        </Row>

        {dataset.notes && (
          <Row className="title">
            <Col sm={12} md={4} lg={3}>
                <b>Notes</b>
            </Col>
            <Col sm={12} md={8} lg={9}>
              <Anchorme target="_blank" rel="noreferrer noopener">
                {dataset.notes}
              </Anchorme>
            </Col>
          </Row>
        )}
        
        {dataset.show_download_count!== false && (
          <Row>
            <Col className="metric" sm={12} md={12} lg={3}>
              <b>Data Download Count</b>
              <Row>
                <Col className="background">
                  {numberFileDownloads} Total Downloads
                </Col>
              </Row>
            </Col>

            {showDownloadContent()}
          </Row>
        )}

        {dataset.custom_images && (
          <>
            <Row>
              <Col md={12} lg={6}>
                {dataset.custom_images[0]}
                <p>The LobbyView dataset combs through and disambiguates lobbyists' prior work experience, allowing us to, for the first time, track the congressional committees that a lobbyist has worked for to find possible political connections or areas of expertise and influence.</p>
              </Col>

              <Col md={12} lg={6}>
                {dataset.custom_images[1]}
                <p>The LobbyView dataset combs through and disambiguates lobbyists' prior work experience, allowing us to, for the first time, track the government agencies at which a lobbyist worked and use that to extract specific issues in which they have unique knowledge or influence.</p>
              </Col>
            </Row>
            <Row>
              <Col md={12} lg={3}/>
              <Col md={12} lg={6}>
                {dataset.custom_images[2]}
              </Col>
              <Col md={12} lg={3}/>
            </Row>
          </>
        )}
        

        <Row className="footer">
          <Col>
            <center><Link to={ROUTES.DATASETS}><Button className="roundTealButton">Back</Button></Link></center>
          </Col>
        </Row>
      </Container>
    )
  } else {
    return(
      <Container className="datapage">
        <Row className="citation">
          <Col sm={12} md={12} lg={12}>
            <h1><center><b>Information for this dataset is not available.</b></center></h1>
          </Col>
        </Row>

        <Row className="footer">
          <Col>
            <center><Link to={ROUTES.DATASETS}><Button className="roundTealButton">Back</Button></Link></center>
          </Col>
        </Row>
      </Container>
    )
  }
}

Datapage.propTypes = {
  client: PropTypes.object.isRequired,
}

export default withApolloClient(Datapage)
