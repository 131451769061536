import gql from 'graphql-tag';

/**
 * given a filename, return the query to get the total number of downloads
 * @param  {String} fileName file name string
 * @return {Graphql Query}
 */
export default function getTotalDownloads(fileName) {
  return gql`
  query forAListOfFileNameGetTotaldownloads{
    getFileNDownloads(_fileName: ["${fileName}"]) {
      fileName
      nDownloads
    }
  }
  `
}
